import { ConfigProvider, Drawer, Menu } from 'antd';
import classNames from 'classnames';
import { useConfigProvider } from '@/context/ConfigProvider';
import styles from './index.module.less';
import { AppSettings } from '@/shared/app-common';
import { CloseOutlined } from '@ant-design/icons';
import { useUserProvider } from '@/context/UserProvider';
import Image from 'next/image';
import { MarketplaceNavItem, navItems } from '@/shared/nav-items';
import { useState } from 'react';
import Link from 'next/link';

const NavHamburger = () => {
  const { isMobile } = useConfigProvider();
  const [open, setOpen] = useState(false);
  const { isLogin, onOpenLoginModal, onChangedLoginView, onSignOut } = useUserProvider();

  const dataList = isLogin
    ? [
        {
          text: 'Dashboard',
          path: '/dashboard',
        },
        {
          text: 'Settings',
          path: '/settings',
        },
        {
          text: 'My Credits',
          path: '/my-credits',
        },
        {
          text: 'PaymentHistory',
          path: '/billing-records',
        },
        {
          text: 'LogOut',
        },
      ]
    : [
        {
          text: 'Sign In/Sign Up',
        },
      ];

  const renderMenuItem = (
    item: MarketplaceNavItem,
    fontSize: number = 16,
    fontFamily: string = 'Inter-Semi',
    fontWeight: number = 600,
  ) => {
    const hasChildren = item.dropdownItems?.length || item.children?.length;

    if (hasChildren) {
      return (
        <Menu.SubMenu
          key={item.text}
          title={
            <>
              {item.path ? (
                <Link
                  href={'/'}
                  onClick={() => {
                    setOpen(false);
                    window.location.href = item.path;
                  }}
                >
                  {item.text}
                </Link>
              ) : (
                item.text
              )}
            </>
          }
          style={{ fontFamily: fontFamily, fontSize: fontSize, fontWeight: fontWeight }}
        >
          {item.dropdownItems?.map((subItem) =>
            renderMenuItem(subItem, 14, 'Inter-Regular', fontWeight - 100),
          )}
          {item.children?.map((subItem) =>
            renderMenuItem(subItem, 14, 'Inter-Regular', fontWeight - 100),
          )}
        </Menu.SubMenu>
      );
    }

    return (
      <Menu.Item
        key={item.text}
        style={{ fontFamily: fontFamily, fontSize: fontSize, fontWeight: fontWeight }}
        onClick={() => {
          if (item.path) {
            setOpen(false);
            window.location.href = item.path;
          }
        }}
      >
        {item.text}
      </Menu.Item>
    );
  };

  const handleDataListItemClick = (item: any) => {
    if (!(item?.path?.length > 0)) {
      if (isLogin) {
        onSignOut();
      } else {
        onChangedLoginView(true);
        onOpenLoginModal(true);
      }
    } else {
      setOpen(false);
      window.location.href = item.path;
    }
  };

  return (
    <div
      className={classNames(styles.navHamburgerWarp, {
        [styles.showClose]: open,
      })}
      onClick={() => {
        setOpen(true);
      }}
    >
      {new Array(3).fill(1).map((_, index) => (
        <span key={`burger-${index}`} className={styles.hamburgerLine} />
      ))}
      <Drawer
        style={{ background: `var(--foreground-rgb)` }}
        title={
          <div className={styles.drawerTitle}>
            <Image
              src={AppSettings.logoPath}
              alt={AppSettings.name}
              loading="lazy"
              height={32}
              width={32}
            />
            <Image
              src={AppSettings.namePath}
              alt={AppSettings.name}
              style={{ paddingLeft: '15px' }}
              loading="lazy"
              height={32}
              width={135}
            />
          </div>
        }
        closeIcon={<CloseOutlined style={{ color: `var(--app-text-color)` }} />}
        width={isMobile ? '100%' : 400}
        placement="left"
        onClose={(event) => {
          event.stopPropagation();
          setOpen(false);
        }}
        maskClosable={false}
        open={open}
        rootClassName={styles.leftMenuDrawerWarp}
        key={'left-menu-drawer'}
      >
        <div className={styles.mobileNavMenuWarp}>
          <ConfigProvider
            theme={{
              components: {
                Menu: {
                  itemBg: `var(--foreground-rgb)`,
                  subMenuItemBg: `var(--foreground-rgb)`,
                  darkItemBg: `var(--foreground-rgb)`,
                  darkSubMenuItemBg: `var(--foreground-rgb)`,
                  groupTitleColor: `var(--app-text-color)`,
                  darkGroupTitleColor: `var(--app-text-color)`,
                  darkItemColor: `var(--app-text-color)`,
                },
              },
            }}
          >
            <Menu theme="dark" mode="inline">
              {navItems.map((item) => renderMenuItem(item))}
              <Menu.Divider style={{ background: `#4B4B4B` }} />
              {dataList.map((item) => (
                <Menu.Item
                  key={`dataList-${item.text}`}
                  onClick={() => handleDataListItemClick(item)}
                  style={{ fontFamily: 'Inter-Semi', fontSize: 16, fontWeight: 600 }}
                >
                  {item.text}
                </Menu.Item>
              ))}
            </Menu>
          </ConfigProvider>
        </div>
      </Drawer>
    </div>
  );
};

export default NavHamburger;
