import styles from './index.module.less';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { Dropdown } from 'antd';
import { MarketplaceNavItem } from '@/shared/nav-items';
import { DownOutlined } from '@ant-design/icons';

const NavItem = ({ item, className }: { item: MarketplaceNavItem; className?: string }) => {
  const router = useRouter();

  const getColor = (pathStr: string) => {
    return router.asPath === pathStr ? `var(--app-primary-color)` : `var(--app-text-color)`;
  };

  const handleItemClick = (e: any, item: MarketplaceNavItem) => {
    if (item?.onClick) {
      item.onClick(item);
      return;
    }
    router.push(item?.path || '/');
  };

  const hasNestedChildren = (items?: MarketplaceNavItem[]) => {
    if (!items) return false;
    return items.some((item) => item.children && item.children.length > 0);
  };

  const renderItem = (item: MarketplaceNavItem, index?: string | number) => {
    return (
      <div
        className={classNames(styles.navItemWarp, className)}
        key={index}
        onClick={(e) => handleItemClick(e, item)}
      >
        {item.dropdownItems ? (
          <Dropdown
            menu={{
              items: hasNestedChildren(item.dropdownItems)
                ? [
                    // 多列布局 - 用于有子菜单的情况（如Features）
                    {
                      key: 'multicol-container',
                      label: (
                        <div className={styles.multiColumnContainer}>
                          {item.dropdownItems.map((dropdownItem, index) => (
                            <div key={`col-${index}`}>
                              <div
                                className={styles.columnHeader}
                                onClick={(e) => {
                                  if (!dropdownItem.children || dropdownItem.path) {
                                    e.stopPropagation();
                                    handleItemClick(e, dropdownItem);
                                  }
                                }}
                              >
                                {dropdownItem.text}
                              </div>

                              {dropdownItem.children && dropdownItem.children.length > 0 && (
                                <div className={styles.columnChildren}>
                                  {dropdownItem.children.map((child, childIndex) => (
                                    <div
                                      key={`child-${index}-${childIndex}`}
                                      className={styles.columnChildItem}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleItemClick(e, child);
                                      }}
                                    >
                                      {child.text}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      ),
                    },
                  ]
                : // 单列布局 - 用于没有子菜单的情况（如Blog）
                  item.dropdownItems.map((dropdownItem, index) => ({
                    key: `simple-${index}`,
                    label: (
                      <div
                        className={styles.singleColumnItem}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleItemClick(e, dropdownItem);
                        }}
                      >
                        {dropdownItem.text}
                      </div>
                    ),
                  })),
            }}
            placement="bottom"
            autoAdjustOverflow={true}
            overlayClassName={
              hasNestedChildren(item.dropdownItems)
                ? styles.multiColDropdown
                : styles.singleColDropdown
            }
          >
            <span
              className={styles.navItemText}
              style={{
                color: getColor(item?.path),
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {item.text}
              <DownOutlined style={{ marginLeft: '4px' }} />
            </span>
          </Dropdown>
        ) : (
          <span className={styles.navItemText} style={{ color: getColor(item?.path) }}>
            {item.text}
          </span>
        )}
      </div>
    );
  };
  return renderItem(item, `nav-item`);
};
export default NavItem;
