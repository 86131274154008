export interface MarketplaceNavItem {
  text: string;
  path?: string;
  icon?: { left: string; right: string };
  dropdownItems?: MarketplaceNavItem[];
  children?: MarketplaceNavItem[];
  onClick?: (item: MarketplaceNavItem) => void;
}
export const navItems: MarketplaceNavItem[] = [
  {
    text: 'Features',
    dropdownItems: [
      {
        text: 'Trending Hairstyles in 2025',
        path: '/trending-hairstyles',
        children: [
          {
            text: 'Trending Long Hairstyles 2025',
            path: '/trending-hairstyles/long',
          },
          {
            text: 'Trending Short Hairstyles 2025',
            path: '/trending-hairstyles/short',
          },
          {
            text: 'Best Mid-Length Hairstyles 2025',
            path: '/trending-hairstyles/medium',
          },
        ],
      },
      {
        text: 'Best Hairstyles For Each Hair Type',
        path: '/hair-types',
        children: [
          {
            text: 'Straight Hair',
            path: '/hair-types/straight',
          },
          {
            text: 'Wavy Hair',
            path: '/hair-types/wavy',
          },
          {
            text: 'Curly Hair',
            path: '/hair-types/curly',
          },
          {
            text: 'Thin Hair',
            path: '/hair-types/thin',
          },
          {
            text: 'Thick Hair',
            path: '/hair-types/thick',
          },
        ],
      },
      {
        text: 'Best Hairstyles For Your Face Shape',
        path: '/best-hairstyles-for-face-shape',
        children: [
          {
            text: 'Long Face',
            path: '/best-hairstyles-for-face-shape/longface',
          },
          {
            text: 'Round Face',
            path: '/best-hairstyles-for-face-shape/roundface',
          },
          {
            text: 'Square Face',
            path: '/best-hairstyles-for-face-shape/squareface',
          },
          {
            text: 'Oval Face',
            path: '/best-hairstyles-for-face-shape/ovalface',
          },
          {
            text: 'Heart Face',
            path: '/best-hairstyles-for-face-shape/heartface',
          },
        ],
      },
      {
        text: 'Hair Color Try-On',
        path: '/virtual-hair-color-try-on',
        children: [
          {
            text: 'Blonde Hair Color ',
            path: '/virtual-hair-color-try-on/blonde',
          },
          {
            text: 'Brown Hair Color ',
            path: '/virtual-hair-color-try-on/brown',
          },
          {
            text: 'Grey Hair Color ',
            path: '/virtual-hair-color-try-on/grey',
          },
          {
            text: 'Red Hair Color ',
            path: '/virtual-hair-color-try-on/red',
          },
          {
            text: 'Black Hair Color ',
            path: '/virtual-hair-color-try-on/black',
          },
          {
            text: 'White Hair Color ',
            path: '/virtual-hair-color-try-on/white',
          },
          {
            text: 'Pink Hair Color ',
            path: '/virtual-hair-color-try-on/pink',
          },
          {
            text: 'Purple Hair Color ',
            path: '/virtual-hair-color-try-on/purple',
          },
          {
            text: 'Blue Hair Color ',
            path: '/virtual-hair-color-try-on/blue',
          },
          {
            text: 'Balayage ',
            path: '/virtual-hair-color-try-on/balayage',
          },
        ],
      },
      {
        text: 'HairStyle Gallery',
        path: '/hairstyle',
        children: [
          {
            text: 'Hairstyle',
            path: '/hairstyle',
          },
          {
            text: 'Influencers',
            path: '/influencers',
          },
        ],
      },
    ],
  },
  {
    text: 'Plan',
    path: '/plan',
  },
  {
    text: 'Hair Trends',
    path: '/blog',
    dropdownItems: [
      {
        text: 'For Men',
        path: '/category/male',
      },
      {
        text: 'For Women',
        path: '/category/female',
      },
    ],
  },
  {
    text: 'FAQ',
    path: '/faq',
  },
  {
    text: 'API',
    path: '/hairpaca-api',
  },
];
