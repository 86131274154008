import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { GlobalHeader, GlobalFooter } from '@/components/Layouts';
import { useConfigProvider } from '@/context/ConfigProvider';
import classNames from 'classnames';
import './index.less';
import { useUserProvider } from '@/context/UserProvider';
import { fetchHairstyleRecordsBalanceData } from '@/lib/service';
import { navItems } from '@/shared/nav-items';
interface LayoutProps {
  head?: React.ReactNode;
  children: React.ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
}

const Layout = ({ children, head, showHeader = true, showFooter = true }: LayoutProps) => {
  const { pathname } = useRouter();
  const { isMobile } = useConfigProvider();
  const { isLogin, isShowGiftView, onShowGiftView } = useUserProvider();
  // 添加状态来跟踪TDK加载状态

  const loadBalanceList = () => {
    fetchHairstyleRecordsBalanceData()
      .then((res) => {
        if (res?.code === 0) {
          onShowGiftView(res?.data?.has_survey_reward);
        }
      })
      .catch((e) => {
        // message.error(e?.message);
      });
  };

  useEffect(() => {
    loadBalanceList();
  }, [showHeader, isLogin]);

  return (
    <div className={'layout'}>
      {head}
      {showHeader && (
        <header className={'header'}>
          <GlobalHeader
            isMobile={isMobile}
            isMarketplaceHome={pathname === '/'}
            navItems={[...navItems]}
          />
        </header>
      )}
      <main
        className={classNames(
          'main',
          isMobile ? (isShowGiftView ? 'topBanner' : 'mobile') : isLogin ? '' : 'topBanner',
          showHeader ? '' : 'hiddenTopBanner',
        )}
      >
        {children}
      </main>
      {showFooter && (
        <footer className={'footer'}>
          <GlobalFooter />
        </footer>
      )}
    </div>
  );
};
export default Layout;
