import Image from 'next/image';
import classNames from 'classnames';
import Link from 'next/link';
import styles from './index.module.less';
import RetroTitle from '@/components/Common/RetroTitle';
import { AppSettings, lowerName } from '@/shared/app-common';
import { CommonBaseContent } from '../CommonBackContent';
import { useRouter } from 'next/router';

const GlobalFooter = ({ show = true, className }: { show?: boolean; className?: string }) => {
  const router = useRouter();

  if (!show) {
    return <></>;
  }
  const cls = classNames(styles.globalFooterWarp, className);
  const listData = [
    {
      title: 'FEATURES',
      children: [
        {
          title: 'Hairpaca-Hair Color Try-On',
          link: '/virtual-hair-color-try-on',
        },
        {
          title: 'Hairpaca-Best Hairstyles For Your Face Shape',
          link: '/best-hairstyles-for-face-shape',
        },
        {
          title: 'Hairpaca-Best Hairstyles For Each Hair Type',
          link: '/hair-types',
        },
        {
          title: 'Hairpaca-Trending Hairstyles in 2025',
          link: '/trending-hairstyles',
        },
        {
          title: 'Hairpaca-HairStyle Gallery',
          link: '/hairstyle',
        },
      ],
    },
    {
      title: 'RESOURCES',
      children: [
        {
          title: 'Blog',
          link: '/blog',
        },
        {
          title: 'FAQs',
          link: '/faq',
        },
      ],
    },
    {
      title: 'PRODUCT',
      children: [
        {
          title: 'Pricing',
          link: '/plan',
        },
        {
          title: 'Contact Us',
          link: 'https://docs.google.com/forms/d/1ScDtzAjYtEsJp8yHmxo3FotgLosU1fAYWB-HtqGsHPE/viewform?edit_requested=true',
        },
      ],
    },
    {
      title: 'WORK WITH US',
      children: [
        {
          title: 'API',
          link: '/hairpaca-api',
        },
      ],
    },
  ];

  return (
    <div className={cls}>
      <div className={styles.line} />
      <CommonBaseContent>
        <div className={styles.mainConetent}>
          <div className={styles.logConatiner}>
            <Link href="/">
              <RetroTitle
                text={
                  <div style={{ height: '32px' }}>
                    <Image
                      className={styles.logo}
                      src={AppSettings.logoPath}
                      alt={AppSettings.name}
                      loading="lazy"
                      width={32}
                      height={32}
                    />
                    <Image
                      src={AppSettings.namePath}
                      alt={AppSettings.name}
                      height={32}
                      width={100}
                      style={{
                        objectFit: 'contain',
                      }}
                    />
                  </div>
                }
                fontSize={28}
                showAlert={true}
              />
            </Link>
          </div>
          <div className={styles.otherConatiner}>
            <div className={styles.linkContainer}>
              {listData.map((item, index) => {
                return (
                  <div key={item?.title + index} className={styles.linkGroup}>
                    <span className={styles.linkTitle}>{item?.title}</span>
                    <div className={styles.linkChildren}>
                      {item.children.map((child, childIndex) => {
                        const isExternalLink = child.link.startsWith('http');

                        return isExternalLink ? (
                          <a
                            key={child.title + childIndex}
                            href={child.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.linkChild}
                          >
                            {child.title}
                          </a>
                        ) : (
                          <Link key={child.title + childIndex} href={child.link}>
                            <span className={styles.linkChild}>{child.title}</span>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={styles.copyrightLine} />
        <div className={styles.copyright}>
          <span>© 2025 Hairpaca. All rights reserved.</span>
          <span>
            <Link href="/privacy-policy">Privacy Policy</Link>
            <span style={{ margin: '0 8px' }}>|</span>
            <Link href="/terms-of-use">Terms of Use</Link>
          </span>
        </div>
      </CommonBaseContent>
    </div>
  );
};

export default GlobalFooter;
