import Link from 'next/link';
import { useEffect, useState } from 'react';
import styles from './index.module.less';
import { Button, Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'next-i18next';
import { useUserProvider } from '@/context/UserProvider';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { fetchHairstyleRecordsBalanceData } from '@/lib/service';

const UserContent = () => {
  const {
    isLogin,
    shareUnreadCount,
    creditsCount,
    onCreditsChanged,
    onOpenLoginModal,
    onChangedLoginView,
    onSignOut,
  } = useUserProvider();
  const [firstLetter, setFirstLetter] = useState<string>('');
  const { t } = useTranslation();
  const router = useRouter();

  const items: MenuProps['items'] = [
    {
      key: 'settings',
      label: <Link href={'/settings'}>{t('Settings')}</Link>,
    },
    {
      key: 'my-credits',
      label: <Link href={'/my-credits'}>{t('My Credits')}</Link>,
    },
    {
      key: 'billing-records',
      label: <Link href={'/billing-records'}>{t('PaymentHistory')}</Link>,
    },
    {
      key: 'logout',
      label: (
        <Link href={'/'} onClick={() => onSignOut()}>
          {t('LogOut')}
        </Link>
      ),
    },
  ];

  const loadBanaceList = () => {
    fetchHairstyleRecordsBalanceData()
      .then((res) => {
        if (res?.code === 0) {
          onCreditsChanged(res?.data?.balance);
        }
      })
      .catch((e) => {});
  };
  useEffect(() => {
    if (isLogin) {
      loadBanaceList();
    }
  }, [isLogin, creditsCount]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        if (user?.email?.length > 0) {
          const tempLetter = user?.email[0];
          setFirstLetter(tempLetter.toLocaleUpperCase());
        } else {
          setFirstLetter('');
        }
      } else {
        setFirstLetter(undefined);
      }
    });

    return () => {
      if (isLogin) {
        unsubscribe();
      } else {
        setFirstLetter(undefined);
      }
    };
  }, [isLogin]);
  return (
    <div className={styles.content}>
      {firstLetter != undefined && isLogin ? (
        <div className={styles.loginDiv}>
          <div className={styles.creditDiv}>
            <Image src={'/images/hairstyle/credit_icon.png'} alt={''} width={30} height={30} />
            <span className={styles.creditLabel}>{`Credit: `}</span>
            <span className={styles.creditCount}>{`${creditsCount}`}</span>
          </div>
          <Dropdown menu={{ items }} placement="bottomRight" autoAdjustOverflow={false}>
            <Button
              className={styles.emailIcon}
              onClick={() => {
                router.push('/dashboard');
              }}
            >
              {firstLetter}
              {shareUnreadCount > 0 && <p />}
            </Button>
          </Dropdown>
        </div>
      ) : (
        <Button
          className={styles.login}
          onClick={() => {
            onOpenLoginModal(true);
            onChangedLoginView(true);
          }}
        >
          {t('Sign In/Sign Up')}
        </Button>
      )}
    </div>
  );
};

export default UserContent;
